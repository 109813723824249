import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast
} from "@chakra-ui/react";
import { getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { Experience, Options, Portfolio } from "../data/AssignedData";
import { collection, addDoc, doc,setDoc } from "firebase/firestore";
import { partnerDB, clientDB } from "config/firebase"; // Adjust the path according to your project structure
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PartnerTable = ({ columnData, tableData, enquiryData }) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [EnquiryData,setEnquiryData] = useState(enquiryData);
  const [sortExperience, setSortExperience] = useState([]);
  const [sortPortfolio, setSortPortfolio] = useState([]);
  const [selectedValue, setSelectedValue] = useState("default");
  const [selectedExperience, setSelectedExperience] = useState("0-3");
  const [selectedPortfolio, setSelectedPortfolio] = useState("yes");
  const pageCount = Math.ceil(tableData.length / pageSize);
  const toast=useToast();

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return tableData.slice(startIndex, endIndex);
  }, [tableData, currentPage, pageSize]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  
  const handlePageSizeChange = (e) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleCheckBoxChange = (rowId) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter((id) => id !== rowId);
      } else {
        return [...prevSelected, rowId];
      }
    });
  };

  useEffect(() => {
    setSortOptions(Options);
    setSortExperience(Experience);
    setSortPortfolio(Portfolio);
  }, []);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setSelectedExperience(value);
  };

  const updateEnquiryStatus = async (enquiryId, status, remarks) => {
    try {
      const enquiryRef = doc(
        clientDB,
        "clientFormData",
        EnquiryData.clientId,
        "enquiries",
        enquiryId
      );

      await updateDoc(enquiryRef, {
        status: status,
        remarks: remarks,
        updatedAt: new Date().toISOString(),
        assignedAt: new Date().toISOString()
      });

      return true;
    } catch (error) {
      console.error("Update error:", error);
      throw error;
    }
  };

  const handleAssign = async () => {
    if (selectedRows.length === 0) {
      toast({
        description: "Please select at least one partner",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      // 1. First update the enquiry status to "Assigned"
      if (EnquiryData?.id && EnquiryData?.clientId) {
        const enquiryRef = doc(
          clientDB,
          "clientFormData",
          EnquiryData.clientId,
          "enquiries",
          EnquiryData.id
        );
  
        await updateDoc(enquiryRef, {
          status: "Assigned",
          remarks: "Partners assigned",
          updatedAt: new Date().toISOString(),
          assignedAt: new Date().toISOString()
        });
      }
  
      // 2. Then save the partner assignments
      const assignedLeadCollectionRef = collection(partnerDB, "assignedLead");
      const enquiryDocRef = doc(assignedLeadCollectionRef);
      const partnerCollectionRef = collection(enquiryDocRef, "partners");
  
      let finalData = {
        EnquiryDetails: {
          ...EnquiryData, // Spread all enquiry data
          status: "Assigned", // Ensure status is updated here too
        }
      }
  
      await setDoc(enquiryDocRef, finalData);
  
      for (let rowId of selectedRows) {
        const xdata = tableData[rowId];
        const assignedLeadData = {
          partnerId: xdata?.uid,
          partnerName: xdata?.personal?.firstName || "",
          partnerService: xdata?.services?.service || "",
          experience: xdata?.businessInfo?.experience || "",
          phone: xdata?.personal?.phone || "",
          email: xdata?.personal?.email || "",
          confirmationStatus: "pending"
        }
  
        await addDoc(partnerCollectionRef, assignedLeadData);
      }
  
      toast({
        description: "Partner Assigned Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "solid",
        backgroundColor: "white",
        containerStyle: {
          backgroundColor: "white",
          border: "1px solid #38A169",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "bold",
          padding: "3px",
          boxShadow: "lg",
          color: "#2D3748",
          boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
        },
      });
  
      // Redirect back to enquiries page
      // history.push("/admin/enquiries");
    } catch (error) {
      console.error("Error assigning partners:", error);
      toast({
        description: "Failed to assign partners",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  
    setSelectedRows([]);
  };
  
  

  return (
    <>
      <div>
        <TableContainer whiteSpace="normal">
          <Flex justifyContent="space-between">
            <Flex
              alignItems="center"
              fontWeight="bold"
              fontSize="1.1rem"
              gap={5}
            >
              <Text>Partners</Text>
              <select value={pageSize} onChange={handlePageSizeChange}>
                {[5, 10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </Flex>
            <Flex>
              <Button
                backgroundColor="#407BFF"
                color="#fff"
                onClick={handleAssign}
              >
                Assign
              </Button>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" gap={2} mt={4}>
            <Menu>
              <>
                <MenuButton
                  as={Box}
                  backgroundColor="#D7E9FD"
                  padding={2}
                  height="2.4rem"
                  borderRadius="30px"
                  boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
                  width="100%"
                  cursor="pointer"
                >
                  <Flex justifyContent="space-evenly" alignItems="center">
                    <Text fontSize="15px">Sort By</Text>
                    <ChevronDownIcon
                      marginLeft="0.2rem"
                      fontSize="1.25rem"
                      borderRadius="1rem"
                      backgroundColor="#D9D9D9"
                      cursor="pointer"
                      boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                    />
                  </Flex>
                </MenuButton>
                <MenuList padding={3}>
                  <RadioGroup value={selectedValue} onChange={handleSelect}>
                    <Stack direction="column">
                      {sortOptions.map(({ label, value }) => (
                        <Radio
                          key={value}
                          value={value}
                          isChecked={selectedValue === value}
                          isOpen={true}
                        >
                          {label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </>
            </Menu>

            <Box
              backgroundColor="#D7E9FD"
              padding={2}
              height="2.4rem"
              borderRadius="30px"
              boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
              width="100%"
            >
              <Flex justifyContent="space-evenly" alignItems="center">
                <Text fontSize="15px">City</Text>
                <ChevronDownIcon
                  marginLeft="0.2rem"
                  fontSize="1.25rem"
                  borderRadius="1rem"
                  backgroundColor="#D9D9D9"
                  cursor="pointer"
                  boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                />
              </Flex>
            </Box>
            <Box
              backgroundColor="#D7E9FD"
              padding={2}
              height="2.4rem"
              borderRadius="30px"
              boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
              width="100%"
            >
              <Flex justifyContent="space-evenly" alignItems="center">
                <Text fontSize="15px">Services</Text>
                <ChevronDownIcon
                  marginLeft="0.2rem"
                  fontSize="1.25rem"
                  borderRadius="1rem"
                  backgroundColor="#D9D9D9"
                  cursor="pointer"
                  boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                />
              </Flex>
            </Box>
            <Menu>
              <>
                <MenuButton
                  as={Box}
                  backgroundColor="#D7E9FD"
                  padding={2}
                  height="2.4rem"
                  borderRadius="30px"
                  boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
                  width="100%"
                  cursor="pointer"
                >
                  <Flex justifyContent="space-evenly" alignItems="center">
                    <Text fontSize="15px">Budget</Text>
                    <ChevronDownIcon
                      marginLeft="0.2rem"
                      fontSize="1.25rem"
                      borderRadius="1rem"
                      backgroundColor="#D9D9D9"
                      cursor="pointer"
                      boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                    />
                  </Flex>
                </MenuButton>
                <MenuList padding={3}>
                  <Flex gap={5}>
                    <Button
                      backgroundColor="#F7FCFB"
                      border="1px solid #455A64"
                      fontSize="12px"
                      height={8}
                      width="5rem"
                      justifyContent="start"
                    >
                      ₹ 0
                    </Button>
                    <Button
                      backgroundColor="#F7FCFB"
                      border="1px solid #455A64"
                      fontSize="12px"
                      height={8}
                      width="8rem"
                      justifyContent="start"
                    >
                      ₹ 99,99,99,999
                    </Button>
                  </Flex>
                  <Slider aria-label="slider-ex-1" defaultValue={30} mt={5}>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </MenuList>
              </>
            </Menu>
            <Menu>
              <>
                <MenuButton
                  as={Box}
                  backgroundColor="#D7E9FD"
                  padding={2}
                  height="2.4rem"
                  borderRadius="30px"
                  boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
                  width="100%"
                  cursor="pointer"
                >
                  <Flex justifyContent="space-evenly" alignItems="center">
                    <Text fontSize="15px">Experience</Text>
                    <ChevronDownIcon
                      marginLeft="0.2rem"
                      fontSize="1.25rem"
                      borderRadius="1rem"
                      backgroundColor="#D9D9D9"
                      cursor="pointer"
                      boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                    />
                  </Flex>
                </MenuButton>
                <MenuList padding={3}>
                  <RadioGroup>
                    <Stack
                      direction="column"
                      value={selectedExperience}
                      onChange={handleSelect}
                    >
                      {sortExperience.map(({ label, value }) => (
                        <Radio
                          key={value}
                          value={value}
                          isChecked={selectedExperience === value}
                          isOpen={true}
                        >
                          {label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </>
            </Menu>
            <Menu>
              <>
                <MenuButton
                  as={Box}
                  backgroundColor="#D7E9FD"
                  padding={2}
                  height="2.4rem"
                  borderRadius="30px"
                  boxShadow="0px 0.25rem 0.25rem 0px #817c7c"
                  width="100%"
                  cursor="pointer"
                >
                  <Flex justifyContent="space-evenly" alignItems="center">
                    <Text fontSize="15px">Portfolio</Text>
                    <ChevronDownIcon
                      marginLeft="0.2rem"
                      fontSize="1.25rem"
                      borderRadius="1rem"
                      backgroundColor="#D9D9D9"
                      cursor="pointer"
                      boxShadow="0px 0.25rem 0.25rem 0px #407BFF"
                    />
                  </Flex>
                </MenuButton>
                <MenuList padding={3}>
                  <RadioGroup>
                    <Stack
                      direction="column"
                      value={selectedPortfolio}
                      onChange={handleSelect}
                    >
                      {sortPortfolio.map(({ label, value }) => (
                        <Radio
                          key={value}
                          value={value}
                          isChecked={selectedPortfolio === value}
                          isOpen={true}
                        >
                          {label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </>
            </Menu>
          </Flex>
          <Table
            variant="simple"
            overflow="hidden"
            style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
          >
            <Thead height="4rem">
              <Tr backgroundColor="#D0E3F3">
                <Th>
                  <Checkbox
                    border="0.1px #263238"
                    isChecked={selectedRows.length === tableData.length}
                    onChange={() => {
                      if (selectedRows.length === paginatedData.length) {
                        setSelectedRows([]);
                      } else {
                        setSelectedRows(
                          Array.from(
                            { length: tableData.length },
                            (_, index) => index
                          )
                        );
                      }
                    }}
                  />
                </Th>
                {columnData?.map((item, index) => (
                  <Th key={index}>{item?.Header}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((item, index) => {
                const rowId = (currentPage - 1) * pageSize + index;
                return (
                  <Tr
                    key={rowId}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.25rem 0.25rem 0rem #00000040"
                  >
                    <Td>
                      <Checkbox
                        border="0.1px #263238"
                        isChecked={selectedRows.includes(rowId)}
                        onChange={() => handleCheckBoxChange(rowId)}
                      />
                    </Td>
                    <Td>
                      <Text fontWeight="bold">
                        {item?.personal?.firstName} {item?.personal?.lastName}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontWeight="bold">{item?.businessInfo?.businessName}</Text>
                    </Td>
                    <Td fontWeight="bold">{item?.services?.service}</Td>
                    <Td fontWeight="bold">{item?.businessInfo?.experience} years</Td>
                    <Td fontWeight="bold">{item?.personal?.city}</Td>
                    <Td fontWeight="bold">Available</Td>
                    <Td fontWeight="bold">2 weeks</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="space-between" alignItems="center" m="1rem 2rem">
          <Button
            onClick={handlePreviousPage}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Back
          </Button>
          <Text>
            Page {currentPage} of {pageCount}
          </Text>
          <Button
            onClick={handleNextPage}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Next
          </Button>
        </Flex>
      </div>

      {/* Action Modal Opening */}
    </>
  );
};

export default PartnerTable;
