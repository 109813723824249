import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  Textarea,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import businessCard from "../assets/Img/business-card.png";
import chat from "../assets/Img/chat.png";
import closeBtn from "../assets/Img/closeBtn.png";
import email from "../assets/Img/email.png";
import internet from "../assets/Img/internet.png";
import phoneNo from "../assets/Img/phone-no.png";
import user from "../assets/Img/user.png";
import videoCall from "../assets/Img/video-call.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState, useRef } from "react";
import add from "../assets/logoDetails/add.png";
import adidas from "../assets/logoDetails/adidas.png";
import branding from "../assets/logoDetails/branding.png";
import cadbery from "../assets/logoDetails/cadbery.png";
import colorWheel from "../assets/logoDetails/color-wheel.png";
import flowChart from "../assets/logoDetails/flow-chart.png";
import industrial from "../assets/logoDetails/industrial.png";
import inventory from "../assets/logoDetails/inventory.png";
import oilPrice from "../assets/logoDetails/oil-price.png";
import preferences from "../assets/logoDetails/preferences.png";
import prototype from "../assets/logoDetails/prototype.png";
import revision from "../assets/logoDetails/revision.png";
import schedule from "../assets/logoDetails/schedule.png";
import spacing from "../assets/logoDetails/spacing.png";
import timetable from "../assets/logoDetails/timetable.png";
import voice from "../assets/logoDetails/voice.png";

function LogoEnquiryDetails({ openActionModal, data }) {
  console.log(data);
  const { categoryDetails, personalDetails, projectDetails, serviceDetails } = data;
  const history = useHistory();
  const [callOptions, setCallOptions] = useState(false);
  useEffect(() => {
    const handleClickOutside = () => {
      setCallOptions(false);
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const cancelRef = useRef();

  const handleReject = () => {

    onClose();

    toast({
      title: "Enquiry Rejected",
      description: "The enquiry has been successfully rejected.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
      variant: "solid",
      backgroundColor: "white",
      containerStyle: {
        backgroundColor: "white",
        border: "1px solid #38A169",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "3px",
        boxShadow: "lg",
        color: "#2D3748",
        boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
      },
    })
  };

  const handleEdit = () => {
    toast({
      title: "Enquiry Edited",
      description: "The enquiry has been successfully edited.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
      variant: "solid",
      backgroundColor: "white",
      containerStyle: {
        backgroundColor: "white",
        border: "1px solid #38A169",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "3px",
        boxShadow: "lg",
        color: "#2D3748",
        boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
      },
    })
  };

  return (
    <Flex
      flexDirection={"column"}
      rowGap={{ sm: ".5rem", md: ".7rem" }}
      borderRadius={"20px"}
      padding={"20px"}
      color={"#455A64"}
      fontFamily={"Cabin,sans-serif"}
      fontSize={{ sm: "smaller", md: "base" }}
      boxShadow={"4px 4px 4px 5px rgba(0, 0, 0, 0.25)"}
    >
      <Flex justifyContent={"space-between"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ sm: "1.150rem", md: "1.8rem" }}
          color="#1B2559"
          paddingX={"8px"}
        >
          {categoryDetails.category}
        </Text>
        <Button
          bg={"transparent"}
          size={{ sm: "xs", md: "sm" }}
          onClick={openActionModal}
        >
          <Image
            src={closeBtn}
            width={{ sm: "15px", md: "20px" }}
            height={{ sm: "15px", md: "20px" }}
          />
        </Button>
      </Flex>
      <Text
        fontSize={{ sm: "1rem", md: "1.5rem" }}
        color={"#1B2559"}
        paddingX={"8px"}
      >
        Details
      </Text>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            User Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={user}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{personalDetails?.fullName}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Phone No
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={phoneNo}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{personalDetails?.phone}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(1,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Email
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={email}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{personalDetails?.email}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Startup Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={businessCard}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{personalDetails?.business}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={internet}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{personalDetails?.website}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Type of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={spacing}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.TypeOfLogo.join(",")}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Style of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={prototype}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.LogoStyle.join(",")}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Concept
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={inventory}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.LogoConcepts}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Revisions
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={revision}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.Revisions}</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            TAT
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={timetable}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.projectTimeline}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={schedule}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>24 jan 2025, 5:05PM </Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Pricing
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={oilPrice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>{projectDetails?.priceRange[0] + "-" + projectDetails?.priceRange[1]}</Text>
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={add}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Additional Branding Assets
          </Text>
        </Flex>
        <Text w={"50%"}>
          {projectDetails?.Assets.join(",")}
        </Text>
      </Flex>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Business Model
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={flowChart}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.BusinessModel}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Industry Type
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={industrial}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.IndustryType}</Text>
          </Flex>
        </Flex>
      </Grid>
      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Color Scheme
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={colorWheel}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>{projectDetails?.Color}</Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        gap={".8rem"}
        padding={".4rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={preferences}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Logo Preference
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={{ sm: "1rem", md: "2rem" }}>
          <Image
            src={cadbery}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
          <Image
            src={adidas}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={voice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Project Requirements
          </Text>
        </Flex>

        <Text>
          {projectDetails?.businessDescription}
        </Text>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={branding}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Branding Proposition
          </Text>
        </Flex>
        <Text>
          {serviceDetails?.brandingProposition}
        </Text>
      </Flex>

      <Flex
        flexDir={"column"}
        fontSize={{ sm: "small", md: "medium" }}
        gap={{ sm: ".2rem", md: ".7rem" }}
        paddingY={"1rem"}
      >
        <Box
          color={"rgba(27, 37, 89, 0.8)"}
          padding={".5rem"}
        >
          <Text>Communication</Text>
          <Flex
            gap=".5rem"
            mt={".3rem"}
            pos={"relative"}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              onClick={() => setCallOptions(!callOptions)}
              variant="unstyled"
              minW={"25px"}
            >
              <Image
                src={chat}
                width={{ sm: "25px", md: "30px" }}
                height={{ sm: "25px", md: "30px" }}
              />
            </Button>
            <Button
              onClick={() => setCallOptions(!callOptions)}
              variant="unstyled"
            >
              <Image
                src={videoCall}
                width={{ sm: "25px", md: "30px" }}
                height={{ sm: "25px", md: "30px" }}
              />
            </Button>
          </Flex>
        </Box>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Quotes</Text>
          <Button
            _hover={{ bg: "#f28f87" }}
            bg={"#407bff"}
            color={"#fff"}
            w={{ sm: "6rem", md: "7rem" }}
            size={"sm"}
          >
            Quotes Link
          </Button>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Take Action</Text>

          <Flex gap={{ sm: "1rem", md: "4rem" }} alignItems={"center"}>
            <Button
              bg={"#407bff"}
              color={"#fff"}
              w={{ sm: "6rem", md: "7rem" }}
              size={"sm"}
              onClick={() =>
                history.push("/admin/assigned-partners", {
                  item: data,
                })
              }
            >
              Assign
            </Button>
            <Button
              bg={"#65c756"}
              color={"#fff"}
              w={{ sm: "7rem", md: "8rem" }}
              size={"sm"}
              onClick={handleEdit}
            >
              Edit Enquiry
            </Button>
            <Button
              bg={"#f28f8f"}
              color={"#fff"}
              w={{ sm: "7rem", md: "8rem" }}
              size={"sm"}
              onClick={onOpen}
            >
              Reject Enquiry
            </Button>
            {/* Confirmation Dialog */}
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Reject Enquiry
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure you want to reject this enquiry? This action cannot be undone.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button borderRadius={"8px"} ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button borderRadius={"8px"} color={"white"} bg={"#f28f8f"} onClick={handleReject} ml={3}>
                      Yes, Reject
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Flex>
        </Flex>
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Remarks</Text>
          <Textarea
            p={"3"}
            bg={"#fff"}
            placeholder="Type your questions?"
            resize={"none"}
          />
          <Button
            bg={"#f28f8f"}
            color={"#fff"}
            w={{ sm: "7rem", md: "8rem" }}
            size={"sm"}
            alignSelf={"flex-end"}
            mt={"2"}
          >
            Reject
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LogoEnquiryDetails;
