import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar, Box,
  Button, Checkbox, CheckboxGroup, Flex,
  FormControl,
  FormLabel, Heading, Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader, ModalOverlay, Select, SimpleGrid,
  Table, TableContainer, Tbody,
  Td,
  Text, Th,
  Thead, Tr
} from "@chakra-ui/react";
import LineChart from "components/chartsFunctionalComponent/LineChart";
import { HSeparator, VSeparator } from "components/separator/Separator";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BsClock, BsThreeDotsVertical } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { GoBell } from "react-icons/go";
import { SlGraph } from "react-icons/sl";
import { TbTag } from "react-icons/tb";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomCard from "views/admin/dashboards/components/CustomCard";
import { Cards } from "views/admin/dashboards/data/DashboardData";
import {
  LineChartData,
  LineChartOptionsData,
} from "views/admin/projects/data/ProjectData";
import LogoEnquiryDetails from "views/admin/servicesDetail/enquiryDetail/LogoEnquiryDetails";
import WebEnquiryDetails from "views/admin/servicesDetail/enquiryDetail/WebEnquiryDetails";
import { assets } from "../../../../assets/img/assets";
import { clientDB } from "../../../../config/firebase";

const Enquiries = () => {
  const [openActionModal, setOpenActionModal] = useState(false);
  const [selectedService, setSelectedService] = useState("Web Development");
  const [decisionParameters, setDecisionParameters] = useState([]);
  const [formData, setFormData] = useState([]);
  const [modalData, setModalData] = useState({});
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("New Enquiries");
  const colData = [
    "Service",
    "Date",
    "Name",
    "Budget",
    "Industry",
    "Timeline",
    "Status",
    "Engage",
  ];

  const serviceDetails = {
    "Web Development": {
      serviceName: "Web Development",
      timeline: "3 Months",
      cost: "$2000",
      purposeOfWebsite: "E-commerce",
      numberOfPages: "20",
      featuresRequired: ["SEO Optimization", "Forms", "Live Chat"],
    },
    Design: {
      serviceName: "Design",
      timeline: "2 Months",
      cost: "$1500",
      platform: "Web",
      designScope: "Redesign",
      featuresOrPages: "Homepage, About Us",
      targetAudience: "General Public",
    },
    SEO: {
      serviceName: "SEO",
      timeline: "1 Month",
      cost: "$1000",
      websiteURL: "www.example.com",
      targetKeywords: "SEO, Web Development",
      goal: ["Improve Rankings", "Increase Traffic"],
      competitorWebsites: "www.competitor1.com",
      existingSEOEfforts: {
        hasEfforts: true,
        details: "Previous SEO work on blogs",
      },
    },
    Marketing: {
      serviceName: "Marketing",
      timeline: "1 Month",
      cost: "$1200",
      emailPlatform: "Mailchimp",
      targetAudienceMarketing: "B2B",
      numberOfEmails: "11-50",
      campaignGoals: ["Increase Sales", "Drive Traffic"],
    },
  };

  const handleModal = (item) => {
    setModalData(item);
  };

  const getData = async () => {
    try {
      const collectionRef = collection(clientDB, "clientFormData");
      const docSnapshot = await getDocs(collectionRef);
      const enquiryArray = [];
  
      if (!docSnapshot.empty) {
        const docIds = docSnapshot.docs.map((item) => item.id);
  
        for (let clientId of docIds) {
          const enquiryCollectionRef = collection(
            clientDB,
            `clientFormData/${clientId}/enquiries`
          );
          const enquirySnapshot = await getDocs(enquiryCollectionRef);
          if (!enquirySnapshot.empty) {
            const newData = enquirySnapshot.docs.map((item) => ({
              id: item.id,
              clientId: clientId,  // Add this line to include clientId
              ...item.data()
            }));
            enquiryArray.push(...newData);
          }
        }
      }
      setFormData(enquiryArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("Modal data:", modalData);
    setDecisionParameters(modalData?.serviceDetails?.decisionParameters);
  }, [modalData]);

  useEffect(() => {
    console.log("Decision Parameters:", decisionParameters);
  }, [decisionParameters]);

  useEffect(() => { }, [openActionModal]);

  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartOptionsData, setLineChartOptionsData] = useState([]);
  useEffect(() => {
    setLineChartData(LineChartData);
    setLineChartOptionsData(LineChartOptionsData);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // ... (previous state declarations remain the same) ...
  const [serviceFilter, setServiceFilter] = useState(null);
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);

  // Available service options for filtering
  const serviceOptions = [
    "Website Development",
    "Logo Design",
    "SEO",
    "Marketing"
  ];

  // Filter enquiries based on selected service
  const filteredEnquiries = formData.filter(item => {
    // First apply service filter if active
    if (serviceFilter && item?.categoryDetails?.category !== serviceFilter) {
      return false;
    }
    
    // Then apply status filter based on active tab
    switch (activeTab) {
      case "New Enquiries":
        return item.status === "New" || item.status === "pending";
      case "Assigned Enquiries":
        return item.status === "Assigned";
      case "Completed Enquiries":
        return item.status === "Completed";
      case "Rejected Enquiries":
        return item.status === "Rejected";
      default:
        return true;
    }
  });

  // Check if filtered enquiries are empty
  const noEnquiriesForService = serviceFilter && filteredEnquiries.length === 0;

  // Calculate total pages based on formData length and leadsPerPage
  const totalPages = Math.ceil(formData.length / leadsPerPage);

  // Sort and paginate the filtered enquiries
  const sortedEnquiries = [...filteredEnquiries].sort((a, b) => {
    const parseDate = (dateStr) => {
      const [day, month, year, time] = dateStr.split(/[/ ]/);
      const [hour, minute, second] = time.split(":").map(num => num.padStart(2, "0"));
      return new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T${hour}:${minute}:${second}`);
    };
    return parseDate(b.createdAt) - parseDate(a.createdAt);
  });

  const indexOfLastEnquiry = currentPage * leadsPerPage;
  const indexOfFirstEnquiry = indexOfLastEnquiry - leadsPerPage;
  const currentEnquiries = sortedEnquiries.slice(indexOfFirstEnquiry, indexOfLastEnquiry);

  // Handle service filter selection
  const handleServiceFilter = (service) => {
    setServiceFilter(service === serviceFilter ? null : service);
    setIsServiceDropdownOpen(false);
    setCurrentPage(1); // Reset to first page when changing filter
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLeadsChange = (value) => {
    setLeadsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing items per page
    setIsDropdownOpen(false);
  };

  // const handleLeadsChange = (value) => {
  //   setLeadsPerPage(value);
  //   setIsDropdownOpen(false);
  // };

  const updateEnquiryStatus = async (enquiryId, status, remarks) => {
    try {
      // Find the enquiry with clientId
      const enquiry = formData.find(item => item.id === enquiryId && item.clientId);
      
      if (!enquiry) {
        throw new Error(`Enquiry ${enquiryId} not found or missing clientId`);
      }
  
      const enquiryRef = doc(
        clientDB,
        "clientFormData",
        enquiry.clientId,
        "enquiries",
        enquiryId
      );
  
      await updateDoc(enquiryRef, {
        status: status,
        remarks: remarks,
        updatedAt: new Date().toISOString(),
        ...(status === "Assigned" && { assignedAt: new Date().toISOString() }),
        ...(status === "Rejected" && { rejectedAt: new Date().toISOString() })
      });
  
      // Refresh data
      await getData();
      return true;
    } catch (error) {
      console.error("Update error:", {
        error,
        enquiryId,
        formData: formData.map(item => ({ id: item.id, clientId: item.clientId }))
      });
      throw error;
    }
  };

  const filteredEnq = serviceFilter
  ? formData.filter(item => 
      item?.categoryDetails?.category === serviceFilter && 
      item?.status === activeTab.replace(" Enquiries", "")
    )
  : formData.filter(item => 
      item?.status === activeTab.replace(" Enquiries", "")
    );

    
  return (
    <>
      <Box pt="0">
        <Flex
          position="absolute"
          top="3"
          justifyContent="space-between"
          alignItems="center"
          width="94%"
        >
          <span className="text-gray-700 font-bold text-2xl">Projects</span>

          <div className="flex items-center space-x-2 bg-gray-200 px-4 py-2 rounded-md">
            <FiSearch className="text-gray-500 text-lg" />
            <input
              type="text"
              placeholder="Search..."
              className="bg-transparent border-none focus:outline-none text-gray-700 placeholder-gray-500"
            />
          </div>

          <div className="flex items-center space-x-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-gray-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>

            <div className="flex items-center space-x-3">
              <img
                className="w-10 h-10 rounded-full"
                src={assets.avatar1}
                alt="Partner"
              />
              <div className="flex flex-col">
                <span className="font-bold text-gray-700">Partner Name</span>
                <h1 className="font-bold text-gray-500">CRB</h1>
              </div>
            </div>
          </div>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mt={20}
        >
          <Heading size="lg" mb={4}>
            Overview
          </Heading>
          <Flex
            alignItems="center"
            gap="0.5rem"
            cursor="pointer"
            bg="blue.100"
            px="1rem"
            py="0.5rem"
            borderRadius="md"
          >
            <Text fontSize="md" fontWeight="medium">
              Last 7 Days
            </Text>
            <Icon as={ChevronDownIcon} width="20px" height="20px" />
          </Flex>
        </Flex>
        <Flex padding="1rem" flexWrap="wrap" justifyContent="space-between">
          {Cards.map((item, index) => (
            <CustomCard key={index} card={item} />
          ))}
        </Flex>
        <Flex
          mt="1rem"
          mb="4rem"
          flexDirection="column"
          backgroundColor="#FFFFFF"
          minHeight="15rem"
          borderRadius="1.1rem"
          boxShadow="0px 4px 38px 0px #407BFF26"
        >
          <Flex p="0.7rem" justifyContent="space-between" alignItems="center">
            <Flex fontSize="xs" mt="1rem" ml="1rem">
              <Text mr="1rem">Projects</Text>
              <VSeparator height="1.5rem" />
              <Text ml="1rem">Current Week</Text>
              <Text ml="1rem">Previous Week</Text>
            </Flex>
            <Flex>
              <Icon as={BsThreeDotsVertical} />
            </Flex>
          </Flex>
          <LineChart
            chartData={LineChartData}
            chartOptions={lineChartOptionsData}
          />
        </Flex>
        <TableContainer
          border="3px solid"
          borderRadius="0.5rem"
          borderColor="#D3D3D3"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            m="1rem 1.5rem"
          >
            <Flex justifyContent="flex-start" gap="1.1rem">
              {["New Enquiries", "Assigned Enquiries", "Completed Enquiries", "Rejected Enquiries"].map(
                (tab) => (
                  <Flex
                    key={tab}
                    alignItems="center"
                    fontWeight="bold"
                    fontSize="1.1rem"
                    cursor="pointer"
                    position="relative"
                    onClick={() => setActiveTab(tab)}
                    pb="5px"
                  >
                    <Text fontSize={"1rem"} opacity={activeTab === tab ? "0.6" : "1"}>{tab}</Text>
                    {activeTab === tab && (
                      <Flex
                        position="absolute"
                        bottom="0"
                        left="50%"
                        transform="translateX(-50%)"
                        width="100%"
                        height="2.4px"
                        backgroundColor="blue.500"
                        borderRadius="full"
                      />
                    )}
                  </Flex>
                )
              )}
            </Flex>

            <Flex gap="1rem" alignItems="center">
              <Flex alignItems="center" gap="0.5rem">
                <Button
                  bg="blue.500"
                  color="white"
                  borderRadius="md"
                  _hover={{ bg: "blue.600" }}
                  display="flex"
                  alignItems="center"
                  gap="0.8rem"
                >
                  <Icon as={CiCirclePlus} w="1.7rem" h="1.7rem" />
                  Add Lead
                </Button>
              </Flex>

              {/* <Icon as={MdMoreVert} cursor="pointer" width="28px" height="28px" color="inherit" /> */}
            </Flex>
          </Flex>

          <Table
            variant="simple"
            overflowX="hidden"
            style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
          >
            <Thead height="4rem" mb="1rem">
              <Tr backgroundColor="#D0E3F3">
                {colData?.map((item, index) => (
                  <Th key={index}>
                    <Flex alignItems="center" gap="0.2rem">
                      {item}
                      {item === "Service" && (
                        <Box position="relative">
                          <Icon
                            as={ChevronDownIcon}
                            width="18px"
                            height="18px"
                            cursor="pointer"
                            onClick={() => setIsServiceDropdownOpen(!isServiceDropdownOpen)}
                          />
                          {isServiceDropdownOpen && (
                            <Box
                              position="absolute"
                              top="100%"
                              left="0"
                              zIndex="10"
                              bg="white"
                              boxShadow="md"
                              borderRadius="md"
                              p="2"
                              minWidth="200px"
                              height={"170px"}
                              overflow="scroll"
                              sx={{
                                scrollbarWidth: "none",
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                              }}
                            >
                              {serviceOptions.map((service) => (
                                <Box
                                  key={service}
                                  p="2"
                                  cursor="pointer"
                                  _hover={{ bg: "gray.100" }}
                                  onClick={() => handleServiceFilter(service)}
                                  bg={serviceFilter === service ? "blue.50" : "transparent"}
                                >
                                  {service}
                                </Box>
                              ))}
                              <Box
                                p="2"
                                cursor="pointer"
                                _hover={{ bg: "gray.100" }}
                                onClick={() => handleServiceFilter(null)}
                                bg={!serviceFilter ? "blue.50" : "transparent"}
                                // fontWeight={!serviceFilter ? "bold" : "normal"}
                                fontWeight={"bold"}
                              >
                                All Services
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {noEnquiriesForService ? (
                <Tr>
                <Td colSpan={colData.length} textAlign="center" py={10}>
                  <Text fontSize="lg" fontWeight="bold">
                    No {activeTab.toLowerCase()} found
                    {serviceFilter ? ` for ${serviceFilter}` : ''}
                  </Text>
                </Td>
              </Tr>
              ) : (
                currentEnquiries.map((item, index) => (
                  <Tr boxShadow="0rem 0.25rem 0.25rem 0rem #00000040" key={index} backgroundColor="#EBF2FA">
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.categoryDetails?.category}
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.createdAt.split(" ")[0]}
                        </Text>
                        <Text fontSize="sm">{item?.createdAt.split(" ")[1]}</Text>
                      </Flex>
                    </Td>
                    <Td>{item?.personalDetails?.fullName}</Td>
                    <Td fontWeight="bold">
                      ₹{item?.projectDetails?.priceRange[0]}-
                      {item?.projectDetails?.priceRange[1]}
                    </Td>
                    <Td fontWeight="bold">
                      {item?.serviceDetails?.purposeOfWebsite}
                    </Td>
                    <Td fontWeight="bold">
                      {item?.projectDetails?.projectTimeline}
                    </Td>
                    <Td fontWeight="bold">{item?.status}</Td>
                    <Td>
                      <Button
                        backgroundColor="#9BC5EF"
                        borderRadius="2rem"
                        cursor="pointer"
                        onClick={() => {
                          setOpenActionModal(!openActionModal);
                          setSelectedService(item?.categoryDetails?.category);
                          handleModal(item);
                        }}
                      >
                        Action
                      </Button>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center w-full px-6 mt-4">
          <div className="flex items-center justify-center gap-2 flex-grow">
            {/* Previous Button */}
            <button
              onClick={handlePrevClick}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded-md border ${currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-500 text-white"
                }`}
            >
              ‹ Prev
            </button>

            {/* Page Numbers */}
            {[...Array(totalPages)].map((_, index) => {
              const pageNumber = index + 1;
             
              if (
                pageNumber === 1 ||
                pageNumber === totalPages ||
                (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
              ) {
                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageClick(pageNumber)}
                    className={`px-3 py-1 rounded-md border ${currentPage === pageNumber
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                      }`}
                  >
                    {pageNumber}
                  </button>
                );
              }
              return null;
            })}

            {/* Next Button */}
            <button
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
              className={`px-3 py-1 rounded-md border ${currentPage === totalPages
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-500 text-white"
                }`}
            >
              Next ›
            </button>
          </div>

          {/* Items per page dropdown */}
          <div className="relative">
            <div
              className="flex items-center justify-between w-20 px-3 py-1 border rounded-md cursor-pointer bg-white"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span>{leadsPerPage}</span>
              <ChevronDownIcon
                width="18px"
                height="18px"
                className="text-gray-700"
              />
            </div>

            
            {isDropdownOpen && (
              <ul className="absolute right-0 mt-2 w-20 bg-white border rounded-md shadow-lg z-10">
                {[10, 15, 20, 25].map((option) => (
                  <li
                    key={option}
                    onClick={() => handleLeadsChange(option)}
                    className="px-3 py-1 cursor-pointer hover:bg-gray-200 text-center"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {/* Action Modal Opening */}
        <Modal
          size="xl"
          isOpen={openActionModal}
          onClose={() => {
            setOpenActionModal(!openActionModal);
          }}
        >
          <ModalOverlay />
          <ModalContent backgroundColor="#EBF2FA">
            {modalData?.categoryDetails?.category === "graphic design" ? (
              <ModalBody
                padding={"0px"}
                borderRadius={"20px"}
                overflow={"hidden"}
              >
                <LogoEnquiryDetails
                  openActionModal={() => setOpenActionModal(!openActionModal)}
                  data={modalData}
                />
              </ModalBody>
            ) : modalData?.categoryDetails?.category === "Website Development" ? (
              <ModalBody
                padding={"0px"}
                borderRadius={"20px"}
                overflow={"hidden"}
              >
                <WebEnquiryDetails
                  openActionModal={() => setOpenActionModal(!openActionModal)}
                  data={modalData}
                  updateEnquiryStatus={updateEnquiryStatus}
                />
              </ModalBody>
            ) : (
              <>
                <ModalHeader color="#1B2559">
                  {modalData?.categoryDetails?.category}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>Details</Text>
                  <Flex pt="1rem" pb="1rem">
                    <Flex w="50%">
                      <Avatar size="sm" />
                      <p style={{ marginLeft: "0.5rem" }}>
                        {modalData?.personalDetails?.fullName}
                      </p>
                    </Flex>
                    <Flex w="50%">
                      <p style={{ marginLeft: "0.5rem" }}>
                        {modalData?.categoryDetails?.subCategory}
                      </p>
                    </Flex>
                  </Flex>
                  <Flex pt="1rem" pb="1rem">
                    <Flex w="50%" alignItems="center">
                      <Icon as={SlGraph} />
                      <p style={{ marginLeft: "0.5rem" }}>
                        {modalData?.serviceDetails?.purposeOfWebsite}
                      </p>
                    </Flex>
                    <Flex w="50%" alignItems="center">
                      <Icon as={FaRegCalendarAlt} />
                      <p style={{ marginLeft: "0.5rem" }}>
                        {modalData?.serviceDetails?.projectStart}
                      </p>
                    </Flex>
                  </Flex>
                  <Text>Pricings</Text>
                  <Flex pt="1rem" pb="1rem">
                    <Flex w="50%" alignItems="center">
                      <Icon as={BsClock} />
                      <p style={{ marginLeft: "0.5rem" }}>
                        {modalData?.projectDetails?.projectTimeline}
                      </p>
                    </Flex>
                    <Flex w="50%" alignItems="center">
                      <Icon as={GoBell} />
                      <p style={{ marginLeft: "0.5rem" }}>Immediately</p>
                    </Flex>
                  </Flex>
                  <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                    <Icon as={TbTag} />
                    <p style={{ marginLeft: "0.5rem" }}>
                      {modalData?.projectDetails?.priceRange[0]}-
                      {modalData?.projectDetails?.priceRange[1]}
                    </p>
                  </Flex>

                  {/* Service Details - Merged Dynamic Fields */}
                  <div>
                    <Text>Service Details</Text>

                    {/* Service Selection */}
                    {/* <Select onChange={(e) => setSelectedService(e.target.value)} value={selectedService} mb="1rem">
                                                  <option value="Web Development">Web Development</option>
                                                  <option value="Design">Design</option>
                                                  <option value="SEO">SEO</option>
                                                  <option value="Marketing">Marketing</option>
                                              </Select> */}

                    {/* Service Name and Timeline */}
                    <Flex pt="1rem" pb="1rem">
                      <Flex w="50%" alignItems="center">
                        <Icon as={BsClock} />
                        <Input
                          ml="0.5rem"
                          variant="flushed"
                          placeholder={
                            serviceDetails[selectedService]?.serviceName ||
                            "Service Name"
                          }
                        />
                      </Flex>
                      <Flex w="50%" alignItems="center">
                        <Icon as={GoBell} />
                        <Input
                          ml="0.5rem"
                          variant="flushed"
                          placeholder={
                            serviceDetails[selectedService]?.timeline ||
                            "Timeline"
                          }
                        />
                      </Flex>
                    </Flex>

                    {/* Cost */}
                    <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                      <Icon as={TbTag} />
                      <Input
                        ml="0.5rem"
                        variant="flushed"
                        placeholder={
                          serviceDetails[selectedService]?.cost || "Cost"
                        }
                      />
                    </Flex>

                    {selectedService === "Web Development" && (
                      <div>
                        <Text>Purpose of Website</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.purposeOfWebsite}
                        </p>
                        <Text>Number of Pages</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.numberOfPages}
                        </p>
                        <Text>Features Required</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.featuresRequired}
                        </p>
                      </div>
                    )}

                    {selectedService === "Design" && (
                      <div>
                        <Text>Platform</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.platform}
                        </p>
                        <Text>Design Scope</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.designScope}
                        </p>
                        <Text>Features or Pages to Design</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.pages}
                        </p>
                        <Text>Target Audience</Text>
                        <p style={{ marginLeft: "0.5rem" }}>
                          {modalData?.serviceDetails?.targetAudience}
                        </p>
                      </div>
                    )}

                    {selectedService === "SEO" && (
                      <div>
                        <Text>Website URL</Text>
                        <Input
                          variant="flushed"
                          placeholder={
                            serviceDetails["SEO"]?.websiteURL ||
                            "www.example.com"
                          }
                        />
                        <Text>Target Keywords/Topics</Text>
                        <Input
                          variant="flushed"
                          placeholder={
                            serviceDetails["SEO"]?.targetKeywords ||
                            "SEO, Web Development"
                          }
                        />
                        <Text>Goal</Text>
                        <CheckboxGroup
                          value={serviceDetails["SEO"]?.goal || []}
                        >
                          <Checkbox value="Improve Rankings">
                            Improve Rankings
                          </Checkbox>
                          <Checkbox value="Increase Traffic">
                            Increase Traffic
                          </Checkbox>
                          <Checkbox value="Local SEO">Local SEO</Checkbox>
                          <Checkbox value="E-commerce SEO">
                            E-commerce SEO
                          </Checkbox>
                        </CheckboxGroup>
                        <Text>Competitor Websites (Optional)</Text>
                        <Input
                          variant="flushed"
                          placeholder={
                            serviceDetails["SEO"]?.competitorWebsites ||
                            "www.competitor1.com"
                          }
                        />
                        <Text>Existing SEO Efforts</Text>
                        <Input
                          variant="flushed"
                          placeholder={
                            serviceDetails["SEO"]?.existingSEOEfforts
                              ?.details || "None"
                          }
                        />
                      </div>
                    )}

                    {selectedService === "Marketing" && (
                      <div>
                        <Text>Email Platform Used</Text>
                        <Input
                          variant="flushed"
                          placeholder={
                            serviceDetails["Marketing"]?.emailPlatform ||
                            "Mailchimp"
                          }
                        />
                        <Text>Target Audience</Text>
                        <Select
                          variant="flushed"
                          placeholder="Select Target Audience"
                        >
                          <option value="B2B">B2B</option>
                          <option value="B2C">B2C</option>
                        </Select>
                        <Text>Number of Emails</Text>
                        <Select
                          variant="flushed"
                          placeholder="Select Number of Emails"
                        >
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51+">51+</option>
                        </Select>
                        <Text>Campaign Goals</Text>
                        <CheckboxGroup
                          value={
                            serviceDetails["Marketing"]?.campaignGoals || []
                          }
                        >
                          <Checkbox value="Increase Sales">
                            Increase Sales
                          </Checkbox>
                          <Checkbox value="Announce Product">
                            Announce Product
                          </Checkbox>
                          <Checkbox value="Drive Traffic">
                            Drive Traffic
                          </Checkbox>
                        </CheckboxGroup>
                      </div>
                    )}
                  </div>

                  <Text pt="0.8rem" pb="0.8rem">
                    Project Requirement
                  </Text>
                  <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                  <p
                    style={{
                      marginTop: "0.8rem",
                      marginBottom: "0.8rem",
                      fontSize: "sm",
                    }}
                  >
                    {modalData?.projectDetails?.projectDetails}
                  </p>
                  <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                  <Text mt="1rem" mb="1rem">
                    Customer Decision Priority
                  </Text>
                  <SimpleGrid columns={3} spacing={5}>
                    <Button
                      w="100%"
                      h="2rem"
                      mr="0.5rem"
                      textAlign="center"
                      backgroundColor={
                        decisionParameters?.includes("Price")
                          ? "#65C756"
                          : "#E0E0E0"
                      }
                      color={
                        decisionParameters?.includes("Price")
                          ? "#fff"
                          : "#263238"
                      }
                      borderRadius="50px 0px 0px 50px"
                    >
                      Cost
                    </Button>
                    <Button
                      w="100%"
                      h="2rem"
                      mr="0.5rem"
                      textAlign="center"
                      backgroundColor={
                        decisionParameters?.includes("Experience")
                          ? "#65C756"
                          : "#E0E0E0"
                      }
                      color={
                        decisionParameters?.includes("Experience")
                          ? "#fff"
                          : "#263238"
                      }
                      borderRadius="0px 0px 0px 0px"
                    >
                      Experience
                    </Button>
                    <Button
                      w="100%"
                      h="2rem"
                      mr="0.5rem"
                      textAlign="center"
                      backgroundColor={
                        decisionParameters?.includes("Quality work")
                          ? "#65C756"
                          : "#E0E0E0"
                      }
                      color={
                        decisionParameters?.includes("Quality work")
                          ? "#fff"
                          : "#263238"
                      }
                      borderRadius="2px 60px 60px 2px"
                    >
                      Quality work
                    </Button>
                    <Button
                      w="100%"
                      h="2rem"
                      mr="0.5rem"
                      textAlign="center"
                      backgroundColor={
                        decisionParameters?.includes("Timeline")
                          ? "#65C756"
                          : "#E0E0E0"
                      }
                      color={
                        decisionParameters?.includes("Timeline")
                          ? "#fff"
                          : "#263238"
                      }
                      borderRadius="50px 0px 0px 50px"
                    >
                      Timeline
                    </Button>
                    <Button
                      w="100%"
                      h="2rem"
                      mr="0.5rem"
                      textAlign="center"
                      backgroundColor={
                        decisionParameters?.includes("Cost structure")
                          ? "#65C756"
                          : "#E0E0E0"
                      }
                      color={
                        decisionParameters?.includes("Cost structure")
                          ? "#fff"
                          : "#263238"
                      }
                      borderRadius="0px 0px 0px 0px"
                    >
                      Payment Structure
                    </Button>
                  </SimpleGrid>
                </ModalBody>

                <Text ml="1.5rem">Take Action</Text>
                <Flex
                  ml="1.5rem"
                  mt="1rem"
                  mb="1rem"
                  justifyContent="flex-start"
                  color="#FFFFFF"
                >
                  <Button
                    backgroundColor="#407BFF"
                    onClick={() =>
                      history.push("/admin/assigned-partners", {
                        item: modalData,
                      })
                    }
                  >
                    Assign Partners
                  </Button>
                  <Button ml="1rem" backgroundColor="#F28F8F">
                    Reject
                  </Button>
                </Flex>

                <FormControl mt="1rem" mb="1rem" w="90%" ml="1.5rem">
                  <FormLabel htmlFor="question">Remarks</FormLabel>
                  <Input
                    id="question"
                    type="text"
                    placeholder="Type your Question"
                    minHeight="6rem"
                    backgroundColor="#FFFFFF"
                  />
                </FormControl>
                <ModalFooter>
                  <Button
                    backgroundColor="#5B8EF1"
                    variant="solid"
                    mr={3}
                    onClick={() => setOpenActionModal(false)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default Enquiries;
