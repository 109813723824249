// components/Preloader.jsx
import React from "react";

const Preloader = ({ type = "circle" }) => {
  // CSS variables and base styles
  const loaderStyles = `
    .loader {
      --path: #2F3545;
      --dot:rgb(52, 143, 92);
      display: inline-block;
      margin: 0 1rem;
      width: 2.75rem;
      height: 2.75rem;
      position: relative;
    }
    
    .loader svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    
    .loader svg rect,
    .loader svg circle {
      fill: none;
      stroke: var(--path);
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
    
    /* Special style only for triangle */
    .loader svg polygon {
      fill: none;
      stroke: #2563eb;  /* Directly setting blue color for triangle only */
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
    
    .loader.triangle {
      width: 3rem;
    }
    
    .loader .dot {
      position: absolute;
      display: block;
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background: var(--dot);
      top: 2.3125rem;
      left: ${type === "triangle" ? "1.3125rem" : "1.1875rem"};
    }

    /* Animation keyframes remain unchanged */
    @keyframes pathTriangle {
      33% { stroke-dashoffset: 74; }
      66% { stroke-dashoffset: 147; }
      100% { stroke-dashoffset: 221; }
    }
    
    @keyframes dotTriangle {
      33% { transform: translate(0, 0); }
      66% { transform: translate(10px, -18px); }
      100% { transform: translate(-10px, -18px); }
    }
    
    @keyframes pathRect {
      25% { stroke-dashoffset: 64; }
      50% { stroke-dashoffset: 128; }
      75% { stroke-dashoffset: 192; }
      100% { stroke-dashoffset: 256; }
    }
    
    @keyframes dotRect {
      25% { transform: translate(0, 0); }
      50% { transform: translate(18px, -18px); }
      75% { transform: translate(0, -36px); }
      100% { transform: translate(-18px, -18px); }
    }
    
    @keyframes pathCircle {
      25% { stroke-dashoffset: 125; }
      50% { stroke-dashoffset: 175; }
      75% { stroke-dashoffset: 225; }
      100% { stroke-dashoffset: 275; }
    }
  `;

  return (
    <>
      <style>{loaderStyles}</style>
      <div className={`loader ${type === "triangle" ? "triangle" : ""}`}>
        {/* Dot animation */}
        <div
          className="dot"
          style={{
            animation: `${
              type === "triangle" ? "dotTriangle" : "dotRect"
            } 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite`
          }}
        ></div>

        {/* SVG shapes */}
        {type === "circle" && (
          <svg viewBox="0 0 80 80">
            <circle
              cx="40"
              cy="40"
              r="32"
              strokeDasharray="150 50 150 50"
              strokeDashoffset="75"
              style={{
                animation: "pathCircle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite"
              }}
            />
          </svg>
        )}

        {type === "triangle" && (
          <svg viewBox="0 0 86 80">
            <polygon
              points="43 8 79 72 7 72"
              strokeDasharray="145 76 145 76"
              strokeDashoffset="0"
              style={{
                animation: "pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite"
              }}
            />
          </svg>
        )}

        {type === "rectangle" && (
          <svg viewBox="0 0 80 80">
            <rect
              x="8"
              y="8"
              width="64"
              height="64"
              strokeDasharray="192 64 192 64"
              strokeDashoffset="0"
              style={{
                animation: "pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite"
              }}
            />
          </svg>
        )}
      </div>
    </>
  );
};

export default Preloader;