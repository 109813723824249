import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import "./index.css";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import Login from "Auth/Login";
import PrivateRoute from "components/PrivateRoute";
import Preloader from "components/preLoader/PreLoader";

const App = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  // Example: Show loader on initial page load
  React.useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 2000); // Hide after 2 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="min-h-screen bg-[#F5F9FF] flex justify-center items-center">
          <div className="flex">
            <Preloader type="circle" />
            <Preloader type="triangle" />
            <Preloader type="rectangle" />
          </div>
        </div>
      )}

      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path={`/auth`} component={AuthLayout} />
          <PrivateRoute path={`/admin`} component={AdminLayout} />
          <PrivateRoute path={`/rtl`} component={RtlLayout} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </Router>
    </>
  );
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      {/* <ThemeEditorProvider> */}
      <App />
      {/* </ThemeEditorProvider> */}
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);



// import React from "react";
// import ReactDOM from "react-dom";
// import "assets/css/App.css";
// import "./index.css";
// import {
//   Route,
//   Switch,
//   Redirect,
//   BrowserRouter as Router,
// } from "react-router-dom";
// import AuthLayout from "layouts/auth";
// import AdminLayout from "layouts/admin";
// import RtlLayout from "layouts/rtl";
// import { ChakraProvider } from "@chakra-ui/react";
// import theme from "theme/theme";
// import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
// import Login from "Auth/Login";
// import PrivateRoute from "components/PrivateRoute";

// ReactDOM.render(
//   <ChakraProvider theme={theme}>
//     <React.StrictMode>
      
//       <Router>
//         <Switch>
          

//           <Route path="/login" component={Login} />
//           <PrivateRoute path={`/auth`} component={AuthLayout} />

//           <PrivateRoute path={`/admin`} component={AdminLayout} />
//           <PrivateRoute path={`/rtl`} component={RtlLayout} />
//           <Redirect from="/" to="/admin" />
//         </Switch>
//       </Router>
//     </React.StrictMode>
//   </ChakraProvider>,
//   document.getElementById("root")
// );