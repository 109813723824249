import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Progress,
  Text
} from "@chakra-ui/react";

import businessCard from "../assets/Img/business-card.png";
import chat from "../assets/Img/chat.png";
import closeBtn from "../assets/Img/closeBtn.png";
import email from "../assets/Img/email.png";
import internet from "../assets/Img/internet.png";
import phoneNo from "../assets/Img/phone-no.png";
import user from "../assets/Img/user.png";
import videoCall from "../assets/Img/video-call.png";

import oilPrice from "../assets/logoDetails/oil-price.png";
import preferences from "../assets/logoDetails/preferences.png";
import schedule from "../assets/logoDetails/schedule.png";
import timetable from "../assets/logoDetails/timetable.png";
import voice from "../assets/logoDetails/voice.png";
import domain from "../assets/webService/domain.png";
import feature from "../assets/webService/feature.png";
import pages from "../assets/webService/pages.png";
import purpose from "../assets/webService/purpose.png";
import startDate from "../assets/webService/start-date.png";
import techPreference from "../assets/webService/tech-preference.png";
import { useEffect, useState } from "react";

function WebProjectDetails({ openModal }) {
  const [callOptions, setCallOptions] = useState(false);
    useEffect(() => {
      const handleClickOutside = () => {
        setCallOptions(false);
      };

      window.addEventListener("click", handleClickOutside);

      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, []);
  return (
    <Flex
      flexDirection={"column"}
      rowGap={{ sm: ".5rem", md: ".7rem" }}
      borderRadius={"20px"}
      padding={"20px"}
      letterSpacing={"wide"}
      color={"#455A64"}
      fontFamily={"Cabin,sans-serif"}
      fontSize={{ sm: "smaller", md: "large" }}
      boxShadow={"4px 4px 4px 5px rgba(0, 0, 0, 0.25)"}
    >
      <Flex justifyContent={"space-between"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ sm: "1.150rem", md: "1.8rem" }}
          color="#1B2559"
          paddingX={"8px"}
        >
          Web Development
        </Text>
        <Button
          bg={"transparent"}
          size={{ sm: "xs", md: "sm" }}
          onClick={openModal}
        >
          <Image
            src={closeBtn}
            width={{ sm: "15px", md: "20px" }}
            height={{ sm: "15px", md: "20px" }}
          />
        </Button>
      </Flex>
      <Text
        fontSize={{ sm: "1rem", md: "1rem" }}
        color={"#1B2559"}
        paddingX={"8px"}
      >
        Details
      </Text>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            User Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={user}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Jon Doe</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Phone No
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={phoneNo}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>+91852456556</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(1,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Email
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={email}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>bizowl@web.com</Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Startup Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={businessCard}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Bizowl</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={internet}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>www.bizowl.com </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Purpose of Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={purpose}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Wordmark, Modern</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            No of Pages
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={pages}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Minimilist, Modern </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Tech Preference
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={techPreference}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>3</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Design Preference
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={preferences}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Yes</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(1,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={schedule}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>24 jan 2025, 5:05PM </Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={feature}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Feature Required
          </Text>
        </Flex>
        <Grid
          gridTemplateColumns={{ sm: "repeat(2,1fr)", md: "1fr 150px 1fr" }}
          rowGap={"1rem"}
          columnGap={{ sm: ".8rem", md: "1rem" }}
        >
          <Flex flexDirection="column" fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Box>
              <Text
                marginBottom={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Design & User Experience{" "}
              </Text>
              <ul>
                <li>Responsive Design </li>
                <li>Custom UI/UX Design </li>
                <li>Dark Mode Support </li>
                <li>Custom Animations </li>
                <li>Accessibility Compliance</li>
              </ul>
            </Box>
            <Box>
              <Text
                marginY={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Performance & Security{" "}
              </Text>
              <ul>
                <li>Fast Loading Speed </li>
                <li>Performance Optimization</li>
                <li>Security Features (SSL, Firewall, etc.) </li>
                <li>Backup & Restore </li>
                <li>Database Integration</li>
              </ul>
            </Box>
          </Flex>
          <Box fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Text
              marginBottom={{ sm: ".8rem", md: "1rem" }}
              fontSize={{ sm: "", md: ".9rem" }}
              fontWeight={"semibold"}
            >
              Functionality & Features
            </Text>
            <ul width="80%">
              <li>Static Website Development</li>{" "}
              <li>Dynamic Website Development</li>{" "}
              <li>CMS Integration (WordPress, Webflow, etc.)</li>{" "}
              <li>E-commerce Integration (Shopify, WooCommerce, etc.)</li>{" "}
              <li>API Integration</li> <li>Payment Gateway Integration</li>
              <li>User Authentication & Login System</li>{" "}
              <li>Admin Panel / Dashboard</li>
            </ul>
          </Box>
          <Flex flexDirection="column" fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Box>
              <Text
                marginBottom={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Creative & Content Support
              </Text>
              <ul>
                <li>Custom Graphics & Illustrations</li>{" "}
                <li>Stock Images & Icons</li>{" "}
                <li>Copywriting for Website Pages</li>
              </ul>
            </Box>
            <Box>
              <Text
                marginY={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                SEO & Marketing
              </Text>
              <ul>
                <li>SEO Optimization (On-Page SEO, Schema, etc.)</li>{" "}
                <li>Blog Setup</li> <li>Social Media Integration</li>{" "}
                <li>Analytics & Tracking Setup</li>{" "}
                <li>Custom Landing Pages</li>
              </ul>
            </Box>
          </Flex>
        </Grid>
      </Flex>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Domain
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={domain}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Yes, I have both</Text>
          </Flex>
        </Flex>

        <Flex
          flexDirection={"column"}
          gap={"3px"}
          padding={".5rem"}
          borderBottom={"1px solid #98a6af"}
          boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
        >
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Budget
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={oilPrice}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>25-45K</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Timeline
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={timetable}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>4 Weeks</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Start Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={startDate}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Immediately </Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={voice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Project Requirements
          </Text>
        </Flex>

        <Text lineHeight={"5"}>
          We are facing a lot of problem in our organic growth and we want
          someone to improve it. This is our requirement.
        </Text>
      </Flex>

      <Flex
        flexDir={"column"}
        fontSize={{ sm: "small", md: "medium" }}
        gap={{ sm: ".2rem", md: ".7rem" }}
        paddingY={"1rem"}
      >
        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Status</Text>
          <Grid
            gridTemplateColumns={"repeat(4,1fr)"}
            columnGap={{ sm: ".8rem", md: "1.3rem" }}
            marginTop={".4rem"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#D8F9E6"}
              color={"#65c756"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              New
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#D8F9E6"}
              color={"#65c756"}
            >
              Open
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
            >
              In Progress
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              Completed
            </Flex>
          </Grid>
        </Box>

        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Customer Decision Priority</Text>
          <Grid
            gridTemplateRows={"repeat(2,1fr)"}
            gridTemplateColumns={"repeat(3,1fr)"}
            columnGap={{ sm: ".8rem", md: "1.3rem" }}
            rowGap={{ sm: ".5rem", md: "1rem" }}
            marginTop={".4rem"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              Cost
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
            >
              Experience
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              Quality Work
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              Timeline
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
            >
              Ratings
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              Payment Structure
            </Flex>
          </Grid>
        </Box>

        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Communication</Text>
          <Flex
            gap=".5rem"
            mt={".3rem"}
            pos={"relative"}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              onClick={() => setCallOptions(!callOptions)}
              variant="unstyled"
              minW={"25px"}
            >
              <Image
                src={chat}
                width={{ sm: "25px", md: "30px" }}
                height={{ sm: "25px", md: "30px" }}
              />
            </Button>
            <Button
              onClick={() => setCallOptions(!callOptions)}
              variant="unstyled"
            >
              <Image
                src={videoCall}
                width={{ sm: "25px", md: "30px" }}
                height={{ sm: "25px", md: "30px" }}
              />
            </Button>
            {callOptions && (
              <Flex
                position={"absolute"}
                boxShadow={"0px 2px 5px gray"}
                left={"-1rem"}
                bottom={"-25px"}
                bgColor={"#fff"}
                w={"fit-content"}
                px={"2"}
                py={".3rem"}
                borderRadius={"full"}
                zIndex={"5"}
              >
                <Button
                  colorScheme="blue"
                  size="xs"
                  me={".4rem"}
                  gap={"1rem"}
                  px="1rem"
                  py={".1rem"}
                  fontSize={".5rem"}
                  height="1rem"
                >
                  User{" "}
                </Button>
                <Button
                  colorScheme="blue"
                  size="xs"
                  fontSize={".5rem"}
                  height="1rem"
                  py={".1rem"}
                  variant="outline"
                >
                  Partner
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>

        <Flex
          flexDirection="column"
          color={"rgba(27, 37, 89, 0.8)"}
          padding={".5rem"}
        >
          <Text color={"rgba(27, 37, 89, 0.8)"}>Progress</Text>
          <Box position="relative">
            <Progress
              mt={".3rem"}
              w="100%"
              height={{ sm: ".6rem", md: ".7rem" }}
              value={90}
            />
            <Text
              position="absolute"
              transform="translate(10%,-3px)"
              right="0"
              p="0.3rem"
              color={"black"}
            >
              90%
            </Text>
          </Box>
        </Flex>

        <Flex gap={".4rem"} padding={".5rem"} alignItems={"center"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Update Progress</Text>
          <Input
            type="number"
            width="20px"
            height="20px"
            fontSize="sm"
            textAlign="center"
            padding="0"
            borderRadius="4px"
            border="1px solid "
            borderColor={"black"}
            _focus={{ borderColor: "blue.500", boxShadow: "0 0 2px blue.500" }}
          />
          <span>%</span>
        </Flex>

        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Payment Structure</Text>
          <Flex mt={".3rem"}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={{ sm: "6rem", md: "8rem" }}
              height={{ sm: "1rem", md: "2rem" }}
              bg={"#fff"}
              color={"yellow.500"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              50
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={{ sm: "6rem", md: "8rem" }}
              height={{ sm: "1rem", md: "2rem" }}
              bg={"#b5fffd"}
              color={"#06d2a6"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              50
            </Flex>
          </Flex>
        </Box>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Partner's Name</Text>
          <Text fontWeight={"bold"}>Crobstacles</Text>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Payment Status</Text>

          <Flex gap={{ sm: "2rem", md: "6rem" }} alignItems={"center"}>
            <Button
              bg={"#407bff"}
              color={"#fff"}
              w={{ sm: "6rem", md: "7rem" }}
              size={"sm"}
            >
              Received
            </Button>
            <Button
              bg={"#f28f8f"}
              color={"#fff"}
              w={{ sm: "6rem", md: "7rem" }}
              size={"sm"}
            >
              Pending
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WebProjectDetails;
